"use client";

import useDebounce from "@/hooks/useDebounce";
import { usePaginationParams } from "@/hooks/usePagination";
import { Primary_Accent_color } from "@/theme/antTheme";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  CircularProgress,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Empty, Flex } from "antd";
import { startCase } from "lodash";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from "react-icons/md";

export interface Column {
  name: string;
  renderHeader?: () => JSX.Element | string | null;
  renderBody?: (rowData: any) => JSX.Element | string | null;
  sortable?: boolean;
  searchable?: boolean;
  initialSort?: boolean; // Will be treated as the default sort column
  hide?: boolean;
}

interface PaginatedListProps {
  columns: Column[];
  data: any[];
  hideSearch?: boolean;
  numberOfPages?: number;
  totalItems?: number;
  serverPaginated?: boolean;
  loading?: boolean;
  size?: "sm" | "md" | "lg";
  hidePagination?: boolean;
}

const PaginatedList = ({
  columns,
  data,
  hideSearch,
  numberOfPages,
  serverPaginated,
  totalItems,
  loading: propsLoading,
  size = "md",
  hidePagination = false,
}: PaginatedListProps) => {
  // pagination

  const {
    setSortKey,
    setSortDirection,

    setPage,
    setLimit,

    sortKey,
    sortDirection,
    limit,
    page,
    loading: localLoading,
  } = usePaginationParams(serverPaginated);
  const searchParams = useSearchParams();
  const [inputSearch, setInputSearch] = useState(searchParams.get("search"));
  const debouncedSearch = useDebounce(inputSearch, 400);

  useEffect(() => {
    if (inputSearch === undefined || (debouncedSearch?.length === 0 && inputSearch?.length === 0)) return;
  }, [debouncedSearch]);

  const loading = propsLoading || localLoading;
  const noData = data.length === 0 && loading === false;
  return (
    <>
      {loading && (
        <div className="pointer-events-none absolute z-0 w-full p-3 pb-6">
          <Progress
            colorScheme="brand"
            variant=""
            className="w-full "
            isIndeterminate={true}
            size="xs"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      )}

      <>
        <TableContainer className="mt-3">
          {!hideSearch && (
            <InputGroup mb="5">
              <InputLeftElement pointerEvents="none">
                {loading ? <CircularProgress isIndeterminate={true} size="24px" /> : <SearchIcon color="gray.300" />}
              </InputLeftElement>
              <Input
                defaultValue={inputSearch || ""}
                onChange={(e) => {
                  console.log("input changed");
                  setInputSearch(e.target.value);
                }}
                placeholder="Search"
              />
            </InputGroup>
          )}

          <Table size={size}>
            <Thead>
              <Tr>
                {columns.map((column, colIndex) => {
                  if (column.hide) {
                    return null;
                  }

                  return column.sortable ? (
                    <Th
                      cursor="pointer"
                      onClick={() => {
                        if (sortKey === column.name) {
                          if (sortDirection === "asc") {
                            setSortDirection("desc");
                          } else {
                            setSortDirection("asc");
                          }
                        } else {
                          setSortKey(column.name);
                          setSortDirection("asc");
                        }
                      }}
                      key={colIndex}
                    >
                      <HStack>
                        <div>{column.renderHeader ? column.renderHeader() : startCase(column.name)}</div>
                        <div>{sortKey === column.name && (sortDirection === "asc" ? "▲" : "▼")}</div>
                      </HStack>
                    </Th>
                  ) : (
                    <Th key={colIndex}>{column.renderHeader ? column.renderHeader() : startCase(column.name)}</Th>
                  );
                })}
              </Tr>
            </Thead>

            <Tbody>
              {data?.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {columns.map((column, colIndex) => {
                    if (column.hide) {
                      return null;
                    }

                    return <Td key={colIndex}>{column.renderBody ? column.renderBody(row) : row[column.name]}</Td>;
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {noData && (
          <div className="w-full " style={{ height: "350px" }}>
            <div className="absolute left-0 m-auto w-full p-32">
              <Empty description="No Results" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        )}
        {!hidePagination && (
          <Pagination
            page={page}
            setPage={setPage}
            numberOfPages={numberOfPages}
            pageSize={limit}
            setPageSize={setLimit}
            loading={loading}
            numberOfResults={totalItems}
          />
        )}
      </>
    </>
  );
};

const Pagination = ({ page, setPage, numberOfPages, pageSize, setPageSize, loading, numberOfResults }) => (
  <Center pt={4}>
    <div className="flex items-center gap-2">
      <IconButton
        className="rounded border p-1"
        variant={"ghost"}
        size={"md"}
        aria-label="First Page"
        icon={<MdFirstPage fontSize={"2em"} />}
        onClick={() => setPage(1)}
      ></IconButton>
      <IconButton
        className="rounded border p-1"
        onClick={() => setPage(page - 1)}
        isDisabled={page == 1}
        variant={"ghost"}
        size={"md"}
        aria-label="Page Before"
        icon={<MdNavigateBefore fontSize={"2em"} />}
      ></IconButton>
      <span className="flex items-center gap-1 sm:px-4 md:px-16">
        <div>Page</div>
        <strong>
          {page} of {numberOfPages || 1}
        </strong>
        {numberOfResults && <Text color="gray">({numberOfResults})</Text>}
      </span>
      <IconButton
        className="rounded border p-1"
        onClick={() => {
          setPage(page + 1);
        }}
        isDisabled={page >= numberOfPages}
        variant={"ghost"}
        size={"md"}
        aria-label="Next Page"
        icon={<MdNavigateNext fontSize={"2em"} />}
      ></IconButton>
      <IconButton
        className="rounded border p-1"
        onClick={() => {
          setPage(numberOfPages);
        }}
        isDisabled={page >= numberOfPages}
        variant={"ghost"}
        size={"md"}
        aria-label="Last Page"
        icon={<MdLastPage fontSize={"2em"} />}
      ></IconButton>
      <Box>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>
      <Box className="h-12 w-12">
        {loading && <CircularProgress color={Primary_Accent_color} isIndeterminate={true} />}
      </Box>
    </div>
  </Center>
);

export default PaginatedList;
