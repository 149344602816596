import { PlanPermission } from "@/services/permissions/Permissions.service";
import { ProjectPermissionsService } from "@/services/permissions/ProjectPermissions.service";

import { Users } from "@/models";

const usePermission = (user: Users, planPermissions: PlanPermission[]) => {
  const projectPermissionsService = new ProjectPermissionsService(user, false, planPermissions);

  return {
    projectPermissions: projectPermissionsService,
  };
};

export default usePermission;
