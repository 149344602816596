import API from "@/services/API.service";
import type { ReportHtmlOptions } from "@/utils/reportHtml/types";
import sanitize from "sanitize-filename";

export async function getSystemsExport(companyId: string, searchParams: any): Promise<any> {
  const localSearchParams = new URLSearchParams(searchParams);

  //We want everything so we remove the page and limit params
  localSearchParams.delete("page");
  localSearchParams.delete("limit");

  const response = await API.get(`/api/company/${companyId}/projects/export?${localSearchParams.toString()}`);

  if (response.success !== true) throw new Error(response.message);

  return response.csvData;
}

export async function getSystemsExportTemplates(companyId: string, customizationId?: string): Promise<string[]> {
  const searchParams = new URLSearchParams();
  if (customizationId) {
    searchParams.append("customizationId", customizationId);
  }

  const response = await API.get(`/api/company/${companyId}/projects/export/templates?${searchParams.toString()}`);

  if (response.success !== true) throw new Error(response.message);

  return response.templates;
}

export async function generateReport({
  options,
  system,
  slds,
  currentSimulationId,
  name,
}: {
  options: Partial<ReportHtmlOptions>;
  system: any;
  slds: { sldName?: string; sldHtml: string }[];
  currentSimulationId?: string;
  name?: string;
}) {
  const reportName = name || "Site Walk Report";
  const fileName = sanitize(`${reportName.replace(/ /g, "_")}-${system.name.replace(/ /g, "_")}`);
  const body = {
    fileName,
    reportName,
    options,
    slds: options.includeSld ? slds : [],
    currentSimulationId,
  };

  const response = await API.post(`/api/company/${system.companyId}/project/${system.id}/generatePDFv2`, {
    body: JSON.stringify(body),
  });
  if (response.success !== true) throw new Error(response.message);

  return response;
}
