import { getVisiblePlansAPI } from "@/app/apiCalls/plan/plansCRUD";
import { upgradeSubscriptionAPI } from "@/app/apiCalls/subscriptions/subscriptionsCRUD";
import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import Pricing from "@/components/Pricing";
import useFetch from "@/hooks/useFetch";
import { PaymentSystem, Plan } from "@/models";
import { Modal, Skeleton, Typography, message } from "antd";
import { useRouter } from "next/navigation";
import { useContext } from "react";

const ProjectLimit = ({ limit }) => {
  const currentUserContext = useContext(CurrentUserContext);
  const { refreshUserContext, activeSubscription } = currentUserContext;
  const router = useRouter();
  const { data: plans, loading: loadingPlans } = useFetch(getVisiblePlansAPI, {
    args: [],
    fetchOnLoad: true,
  });
  const [modal, contextHolder] = Modal.useModal();
  const { callFetch: upgradeSubscriptionFetch, loading: upgradeLoading } = useFetch(upgradeSubscriptionAPI);

  async function confirmUpgrade(plan: Plan) {
    await modal.confirm({
      title: "Are you sure you want to change your plan?",
      content: `Your new ${plan.name} plan features will be available immediately and your next invoice will show a pro-rated charge for the current billing cycle.`,
      onOk() {
        upgradeSubscription(plan);
      },
      okType: "primary",
      okText: "Upgrade",
    });
  }

  async function upgradeSubscription(plan: Plan) {
    message.loading({ content: "Upgrading your subscription...", key: "upgrade" });
    await upgradeSubscriptionFetch(
      currentUserContext.currentCompany?.id!,
      plan.id,
      activeSubscription?.seatCount,
      activeSubscription?.id,
    );
    message.destroy("upgrade");
    message.success({ content: "Subscription upgraded successfully", key: "upgrade" });
    await refreshUserContext();
    router.refresh();
  }

  return (
    <div>
      {contextHolder}
      <Typography.Title level={5}>You&apos;ve reached your project limit</Typography.Title>
      <Typography.Text>
        Your Condoit basic plan includes {limit} projects. To create new projects and unlock the full Condoit
        experience, you can upgrade your plan. <br /> If you have any questions, please contact our support team at{" "}
        <a href="mailto:support@condoit.io">support@condoit.io</a>
      </Typography.Text>
      {activeSubscription?.paymentSystem === PaymentSystem.STRIPE ? (
        <Pricing plans={plans ?? []} onlyCards={true} onSelection={confirmUpgrade} />
      ) : (
        <div>
          <Typography.Text>
            Your plan is currently managed on mobile. Please use your mobile devices app store to manage your
            subscription.
          </Typography.Text>
        </div>
      )}
      {loadingPlans && <Skeleton active />}
    </div>
  );
};

export default ProjectLimit;
