import { Systems } from "@/models";
import { find, forEach, isNil } from "lodash";
import { PermissionsService, PlanPermission } from "./Permissions.service";

export class ProjectPermissionsService extends PermissionsService {
  get projectLimit(): number {
    const createLimit = this.limitPermission;
    return createLimit?.value ? Number(createLimit.value) : 0;
  }

  get limitPermission(): PlanPermission | undefined {
    const createLimit = find(
      this.planPermissions,
      (planPermission: PlanPermission) =>
        planPermission.permission.name === "project-create-limit" ||
        planPermission.permission.name === "project-create-monthly-limit",
    );
    return createLimit;
  }

  canView(system: Systems, companyId?: string): boolean {
    let canView = false;
    if (this.isSuperAdmin()) return true;

    if ([system.companyId, system.subcontractor].includes(companyId ?? this.user.companyId)) {
      return true;
    }

    this.shouldThrow(canView, "You don't have permission to view this system");

    return canView;
  }

  canDuplicateSystem(): boolean {
    return true;
  }

  canCreate(totalProjects: number): boolean {
    console.debug("Checking if we can create the project");
    let canDo = true;

    const createLimit = this.limitPermission;
    console.debug("Create limit", createLimit);
    console.debug("Total projects", totalProjects);

    if (!isNil(createLimit) && this.projectLimit <= totalProjects) {
      canDo = false;
    }

    if (this.throwOnInvalid && !canDo) {
      throw new Error("You have reached the limit of projects you can create", {
        cause: "project-create-limit",
      });
    }

    return canDo;
  }
}
