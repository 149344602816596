import API from "@/services/API.service";
import type { Companies } from "@/models";
import { useCallback, useState } from "react";

const useGetCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Companies[]>([]);

  const fetchCompanies = useCallback(async (companies: string[]) => {
    try {
      setLoading(true);
      const response = await API.get("/api/companies?companies=" + companies.join(","));
      setCompanies(response.companies);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    companies,
    fetchCompanies,
    loading,
  };
};

export default useGetCompanies;
